// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-effect-button {
    transition: all 0.2s ease-in-out;
  }
  
.hover-effect-button:hover {
background-color: #f3f4f6;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
transform: translateY(-2px);
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  50% { transform: translateX(10px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
}

.shake {
  animation: shake 0.5s ease;
}`, "",{"version":3,"sources":["webpack://./src/pages/Prompt.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;EAClC;;AAEF;AACA,yBAAyB;AACzB,iFAAiF;AACjF,2BAA2B;AAC3B;;AAEA;EACE,KAAK,wBAAwB,EAAE;EAC/B,MAAM,4BAA4B,EAAE;EACpC,MAAM,2BAA2B,EAAE;EACnC,MAAM,4BAA4B,EAAE;EACpC,OAAO,wBAAwB,EAAE;AACnC;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".hover-effect-button {\n    transition: all 0.2s ease-in-out;\n  }\n  \n.hover-effect-button:hover {\nbackground-color: #f3f4f6;\nbox-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);\ntransform: translateY(-2px);\n}\n\n@keyframes shake {\n  0% { transform: translateX(0); }\n  25% { transform: translateX(-10px); }\n  50% { transform: translateX(10px); }\n  75% { transform: translateX(-10px); }\n  100% { transform: translateX(0); }\n}\n\n.shake {\n  animation: shake 0.5s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
