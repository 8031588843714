import React from 'react';

interface PromptSubmitGroupProps {
  count: number;
  walletAddress?: string;
  isReward?: boolean;
  handleDepositPoints: () => void;
  setIsCapsuleModalOpen: (isCapsuleModalOpen: boolean) => void;
  handleDisconnect: () => void;
}

const PromptSubmitGroup: React.FC<PromptSubmitGroupProps> = React.memo(({ count, walletAddress, isReward = false, handleDepositPoints, handleDisconnect, setIsCapsuleModalOpen }) => {

  const buttonClass = 'bg-white w-full text-black md:py-[6px] py-[14px] px-[10px] rounded-[100px] text-[16px] leading-[18px] font-medium hover:bg-white/80 transition-colors';
  const disconnectBtnClass = `${buttonClass} bg-red-700 hover:bg-red-700/80 text-white px-`;

  return (
    <>
      {isReward ?
        <div className='flex flex-col gap-[4px]'>
          <span className='text-white/70 font-400-14-16'>You have received Vana Rewards. Thank you for participating!</span>
          {/* <div className='text-[26px] leading-[32px] font-semibold text-white'>{count / 10000}</div> */}
        </div> :
        <div className="w-full flex justify-center">
          {walletAddress ?
            <div className='flex flex-col gap-4'>
              <div className={buttonClass}>{`${walletAddress?.slice(0, 10)}...`}</div>
              <button
                onClick={() => handleDisconnect()}
                className={`${disconnectBtnClass} text-black`}
              >
                Disconnect
              </button>
            </div>
            :
            <button
              onClick={() => setIsCapsuleModalOpen(true)}
              className={buttonClass}
            >
              Connect
            </button>
          }
        </div>
      }
    </>
  );
});

export default PromptSubmitGroup;
