const networks = {
  moksha: {
    chainId: "14800",
    rpcUrl: "https://rpc.moksha.vana.org",
    chainName: "Vana Moksha Testnet",
    explorerUrl: "https://moksha.vanascan.io",
    currency: "VANA",
    smartContracts: {
      dlp: "0x618814F3e97d5D72DbD192198e3D0b51a8402e8C",
      dataRegistry: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_DATA_REGISTRY || "0x8C8788f98385F6ba1adD4234e551ABba0f82Cb7C",
      teePool: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_TEE_POOL || "0xE8EC6BD73b23Ad40E6B9a6f4bD343FAc411bD99A",
    },
  },
  mainnet: {
    chainId: "1480",
    rpcUrl: "https://rpc.islander.vana.org",
    chainName: "Islander",
    explorerUrl: "https://islander.vanascan.io",
    currency: "VANA",
    smartContracts: {
      dlp: "0x32Dc2E545d543A5041224AA50F0D9359A81244D9",
      dataRegistry: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_DATA_REGISTRY_MAINNET || "0x8C8788f98385F6ba1adD4234e551ABba0f82Cb7C",
      teePool: process.env.REACT_APP_SMART_CONTRACT_ADDRESS_TEE_POOL_MAINNET || "0xE8EC6BD73b23Ad40E6B9a6f4bD343FAc411bD99A",
    },
  },
};

const network = (process.env.REACT_APP_NETWORK || "mainnet") as keyof typeof networks;

if (!Object.keys(networks).includes(network)) {
  throw new Error(`Invalid network type: ${network}`);
}

let networkConfig = networks[network];
if (!networkConfig) {
  networkConfig = {} as any;
  networks[network] = networkConfig;
}

const config = {
  network,
  networkConfig,
};

export { config, networks };
