import { IGetNext, IGetStats } from "./types";

const vanatensorBackendUrl = process.env.REACT_APP_VANATENSOR_API_URL;

const fetchNextPrompt = async (attempts = 0) => {
  try {
    const response = await fetch(`${vanatensorBackendUrl}get_next`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      // If successful response, return data
      const result = await response.json();
      return {
        prompt: result?.prompt,
        responses: [
          { response: result?.responses[0].response_text, model: result?.responses[0].model_name },
          { response: result?.responses[1].response_text, model: result?.responses[1].model_name },
        ],
        uniqueID: result?.unique_id,
      };
    } else if (response.status === 500) {
      // If 500 error, throw error to trigger retry
      throw new Error("500 Internal Server Error");
    } else {
      // Handle other errors if needed
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return null;
    }
  } catch (error) {
    attempts += 1;
    console.error(`Attempt ${attempts} failed`);
    // Add a delay before retrying
    await new Promise((resolve) => setTimeout(resolve, 1000 * attempts));
  }
};

// Recursive function to ensure retry after 3 seconds if res is null
export async function getNextResponse(): Promise<IGetNext | undefined | null> {
  const result = await fetchNextPrompt();
  if (result) {
    return result;
  } else {
    setTimeout(getNextResponse, 2000); // Retry after 3 seconds
  }
}

export async function encryptAndUploadToS3(data: Array<IGetNext>, signature: string): Promise<string> {
  const response = await fetch(`https://inspect-vana-encryption-endpoint-production.up.railway.app/encrypt?`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data, signature }),
  });

  const result = await response.json();
  return result.s3_url;
}

export async function depositPoints(walletAddress: string): Promise<string> {
  const response = await fetch(`${vanatensorBackendUrl}deposit_point`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ walletAddress }),
  });

  const result = await response.json();
  return result;
}
(window as any).depositPoints = depositPoints;

export async function getNextStats(): Promise<IGetStats> {
  const response = await fetch(`${vanatensorBackendUrl}get_stats`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const result = await response.json();
  return {
    totalPrompts: result?.total_prompts,
    avgTimesUsed: result?.avg_times_used,
  };
}
