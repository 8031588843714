// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1, h2, h3 {
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 24px;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

ul {
  padding-left: 44px;
  list-style-type: initial;
}

span {
  font-weight: 700;
}

p, pre, li {
  color: gray;
  margin-bottom: 12px;
}

.code-block p {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Verification.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX","sourcesContent":["h1, h2, h3 {\n  font-weight: 700;\n  margin-top: 24px;\n  margin-bottom: 24px;\n}\n\nh1 {\n  font-size: 32px;\n}\n\nh2 {\n  font-size: 24px;\n}\n\nh3 {\n  font-size: 20px;\n}\n\nul {\n  padding-left: 44px;\n  list-style-type: initial;\n}\n\nspan {\n  font-weight: 700;\n}\n\np, pre, li {\n  color: gray;\n  margin-bottom: 12px;\n}\n\n.code-block p {\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
