import React from 'react';
import './Verification.css';

const Verification: React.FC = () => {
    return (
        <div className='text-white px-[24px] sm:px-[64px] md:px-[120px] xl:px-[400px] py-[80px]'>
            <h1>Proof of Contribution for Inspect Platform Usage Data</h1>
            <h2>a. Authenticity</h2>
            <p>
                Our validation algorithm ensures that all submissions are genuinely made by human
                contributors through their Inspect accounts. Users are required to log in to their accounts
                before submitting any data or making contributions. This login process ensures that each
                contribution is linked to a verified user profile, reducing the possibility of automated or
                fraudulent submissions. By tracking session data and verifying account interactions, we confirm
                that the submitted data originates from genuine platform users.
            </p>

            <h2>b. Ownership</h2>
            <p>
                Each piece of data submitted by users is cryptographically tied to a specific wallet signature. This mechanism ensures that every contribution is securely linked to the individual who owns the Inspect account and wallet, establishing verifiable authenticity and legitimate ownership of the data.
            </p>
            <p>
                To maintain data integrity over time, users are allowed to submit their data at most once every 30 days. This frequency ensures that we capture updated and relevant data as user relationships evolve within the crypto and NFT spaces. This limitation prevents data spamming and encourages users to provide meaningful updates that reflect their current network and activities.
            </p>

            <h2>c. Quality</h2>
            <p>
                The quality of our dataset is derived from years of collecting and analyzing user relationships on
                the Inspect platform. The more connections a user has with other crypto or NFT-focused users,
                the higher their ranking within the dataset. This incentivizes users to build meaningful
                relationships and engage actively with the community to boost their ranking.
            </p>
            <p>
                Our validation algorithm tracks and analyzes these relationships to ensure that data
                submissions reflect genuine interactions within the crypto and NFT ecosystems. Users with
                more relevant connections are seen as more influential and trustworthy, contributing to the
                overall quality of the dataset. This organic ranking system encourages users to continuously
                improve their network, ensuring that the data remains accurate and valuable.
            </p>

            <h2>d. Uniqueness</h2>
            <p>
                We generate a unique hash (signature) for every dataset uploaded and compare it against
                existing records to detect duplicates. This step ensures that each new submission represents
                fresh data. Contributors are welcome to submit updated datasets over time, provided that each
                submission is genuinely unique—verified by the hashing mechanism.
            </p>

            <h3>Technical Implementation</h3>
            <ul>
                <li><span>Cryptographic Wallet Signatures: </span>Every submission is tied to a user’s wallet signature,
                    ensuring data authenticity and ownership.</li>
                <li><span>Hashing Mechanism: </span>Our system uses a secure hashing algorithm to create a unique
                    signature for each dataset submission. This hash is compared against existing records
                    to prevent duplicate entries and ensure data uniqueness.</li>
                <li><span>Encryption: </span>All user data is encrypted both in transit and at rest, ensuring that
                    submissions are protected against unauthorized access or tampering.</li>
            </ul>

            <h3>JSON Schema Example for Inspect Usage Data</h3>
            <div className='code-block'>
                <p>{'{'}</p>
                <div className='px-4'>
                    <p>"handle": "crypto_inspector_01",</p>
                    <p>"description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",</p>
                    <p>"followers": ["user_123", "user_456", "user_789"],</p>
                    <p>"wallet_signature": "0xabc123...",</p>
                    <p>"unique_hash": "d7f81abc6e45b9e3dc67e8a9c782edf1",</p>
                    <p>"ranking": {"{"}"timestamps": [1706313600000, 1706400000000], "views7DaysAdjustedRank": [8, 5]{"}"},</p>
                    <p>"tweets": [{"{"}"id": "1882947402115723377", "text": "Lorem ipsum tweet content", "created_at": "2025-01-25T00:23:38.000Z"{"}"}]</p>
                </div>
                <p>{'}'}</p>
            </div>


        </div>
    )
}

export default Verification;