import React from 'react';
import InspectLogo from "../assets/images/inspect.svg";

const Header: React.FC = () => {

  return (
    <header className={'w-full z-[10]'}>
      <div className="md:px-[20px] px-[16px] py-[20px] flex items-center justify-between gap-2 font-borna">
        <div>
          <a href='/'>
            <img src={InspectLogo} alt='InspectLogo' width={116} height={24} />
          </a>
        </div>
        <a href='/verification' className='text-white'>Verification Principles</a>
        {/* <div className='hidden lg:block'><Link to='/'>Home</Link></div>
          <div className='hidden lg:block'><Link to='/staking'>Staking</Link></div>
          <div className='hidden lg:block'><Link to='/about'>About</Link></div> */}
      </div>
    </header>
  )
}

export default Header;
