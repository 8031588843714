// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_submit_btn__HBaIL {
    width: 100%;
    max-width: 480px;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    background: linear-gradient(90deg, #A2FF03 0%, #00F0FF 100%);
    color: black;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

.Home_submit_btn__HBaIL:disabled {
  background: gray;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,sBAAsB;IACtB,aAAa;IACb,oBAAoB;IACpB,aAAa;IACb,uBAAuB;IACvB,4DAA4D;IAC5D,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,iBAAiB;EACnB;;AAEF;EACE,gBAAgB;AAClB","sourcesContent":[".submit_btn {\n    width: 100%;\n    max-width: 480px;\n    box-sizing: border-box;\n    padding: 16px;\n    border-radius: 100px;\n    display: flex;\n    justify-content: center;\n    background: linear-gradient(90deg, #A2FF03 0%, #00F0FF 100%);\n    color: black;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 20px;\n  }\n\n.submit_btn:disabled {\n  background: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submit_btn": `Home_submit_btn__HBaIL`
};
export default ___CSS_LOADER_EXPORT___;
