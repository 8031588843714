const ProgressBar: React.FC<{ stepDescription: Array<String>, progress: number }> = ({ stepDescription, progress }) => {
  const progressWidth = `${((progress - 1) / stepDescription.length) * 100}%`
  const style = {
    width: `${progressWidth}`,
    backgroundColor: '#97fe14',
    height: '20px',
    borderRadius: '50px',
    transition: 'width 0.5s ease-in-out',
    marginTop: "20px"
  };

  const pgWrapperStyle = {
    position: 'fixed' as const,
    top: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#333333ee',
    zIndex: 100
  } as const;

  const pgContainerStyle = {
    width: "800px",
  }

  return (
    <div style={pgWrapperStyle}>
      <div style={pgContainerStyle}>
        <p className="text-white">{stepDescription[progress - 1]}</p>
        <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '50px' }}>
          <div style={style}></div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;